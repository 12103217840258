<template>
  <div class="qrcode flex_items flex_center">
    <div class="con pos flex_center flex_items">
      <div class="tx abs">
        <van-image
          v-if="headerurl != null"
          round
          style="width: 100%"
          fit="contain"
          class=""
          :src="headerurl"
        ></van-image>
        <van-image
          v-else
          round
          style="width: 100%"
          fit="contain"
          class=""
         :src="require('@/images/home/mtx.png')"
        ></van-image>
      </div>
      <p
        class="bold c0 f20 f-center abs"
        style="top: 15vw; left: 0; width: 100%"
      >
        {{ name }}
      </p>
      <!-- qiu -->
      <img
        :src="require('@/images/qrcode/qrqiu.png')"
        alt=""
        style="width: 100%; top: 0; left: 0"
        class="abs"
      />
      <div id="qrcode" ref="qrcode"></div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      headerurl: "",
      name: "",
    };
  },
  mounted() {
    this.headerurl = this.$route.params.headerurl;
    this.name = this.$route.params.name;
    this.qrcode();
  },
  methods: {
    qrcode(e, n) {
      let qrcode = new QRCode("qrcode", {
        width: 200,
        height: 200,
        text: "https://www.pixie.mobi/?id=" + localStorage.getItem("account"),
      });
    },
  },
};
</script>
<style>
.qrcode {
  width: 100%;
  height: 100%;
  background: url("../../images/qrcode/qrbg.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.con {
  background: #fffaf5;
  box-shadow: 0px 3px 24px #ff7a03;
  opacity: 1;
  border-radius: 20px;
  width: 80vw;
  height: 108vw;
}
.qrcode .tx {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  border: 6px solid white;
  transform: translate(-50%, 0);
  left: 50%;
  overflow: hidden;
  top: -11vw;
}
</style>